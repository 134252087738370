import React from "react";
import "./ChooseInput.css";
import Icon from "../Icon";

const Button = ({title, description, src, selected, onClick}) => {
  return (
    <div className={selected ? "choose_input__button_selected" : "choose_input__button"} onClick={onClick}>
        <div className="choose_input__button_header">
          <Icon src={src} alt="icon" className="choose_input__button__icon"/>
          <h5 className={selected ? "choose_input__button__text_selected" : "choose_input__button__text"}>{title}</h5>
        </div>
        {description && <p className={"choose_input__button__description"}>{description}</p>}
    </div>
  );
}

export function ChooseInputDescription(props) {


  return (
    <div className="choose_input">
      {props.values?.map((value, index) => <Button key={index} onClick={() => props.onClick(value?.index)} selected={value?.index == props.selected} {...value}/>)}
    </div>
  ); 
}
