import React, { memo, useEffect, useMemo, useState } from 'react';
import "../Boxes/IconBoxGrid.css"
import "./PickerInput.css"
import Icon from "../Icon";
import PlusIcon from "../../assets/icons/Plus-Icon.svg";
import TextInputModal from '../modal/TextInputModal';

export default function PickerInput({values, maxLength, onNewClick, onSelectClick, customValue}) {
  const [isVisible, setIsVisible] = useState(false);

  const finalValues = (!customValue || maxLength == values?.filter(value => value.selected)?.length) ? 
  (values || [])?.map(((val, index) => { return {...val, onClick: () => onSelectClick(index)}}))
  :[...(values || [])?.map(((val, index) => { return {...val, onClick: () => onSelectClick(index)}})), {type: "empty", text: "Vlastní", src: PlusIcon,  onClick: () => setIsVisible(true)}];


  function onFinishCallback(value) {
    onNewClick(value);
  }

  return (
    <>
    <Grid
      values={finalValues}
      theme={"dark"}
    />
    <TextInputModal 
      style={{ marginBottom: 0}}
      onFinishCallback={onFinishCallback}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    />
  </>
  )
}


const Grid = React.memo(function IconBoxGrid(props) {
  const { values, type, theme, maxRows  } = props;
  const [maxItemsPerRow, setMaxItemsPerRow] = useState(0);

  useEffect(() => {
    function calculateMaxItems() {
      const container = document.querySelector('.picker_input');
      if (container) {
        const containerWidth = container.offsetWidth;
        const boxWidth = 96 + 8; // width of each box
        setMaxItemsPerRow(Math.floor(containerWidth / (boxWidth - 8)));
      }
    }
    calculateMaxItems();
    window.addEventListener('resize', calculateMaxItems);
    return () => window.removeEventListener('resize', calculateMaxItems);
  }, [values]);

  let displayedRows = Math.ceil(values.length / maxItemsPerRow);
  displayedRows = Math.min(displayedRows, maxRows ? maxRows : displayedRows);

  console.log("displayedRows", displayedRows);
  console.log("maxItemsPerRow", maxItemsPerRow);

  const totalItems = displayedRows * maxItemsPerRow;
  const displayedValues = values.slice(0, totalItems);
  const placeholderCount = totalItems - displayedValues.length;

  return (
    <div className="picker_input">
      {displayedValues.map((value, index) => (
        <div key={index} className="picker_input_box">
           <IconBox key={index} src={value.src} {...value} theme={theme} text={value.text} style={{ marginRight: "var(--dynamic-gap)" }}/>
        </div>
      ))}
      {Array.from({ length: placeholderCount }, (_, index) => (
        <div key={'placeholder-' + index} className="picker_input_box picker_input_box--placeholder"></div>
      ))}
    </div>
  );
})


const IconBox = React.memo(function IconBox(props) {
  const {icon, src, text, onClick, theme, type, selected,  style, ...restProps} = props;
  
  const srcLink = (src && !icon ) ? src : "";

  let themeClass;
  let typeMainClass;
  let typeIconClass;

  switch (type) {
    case "empty":
      typeMainClass = "picker_input_box_box_empty";
      typeIconClass = "picker_input__icon__empty";
      themeClass =  "picker_input_box__text_light";
      break;
    default:
      typeMainClass = "picker_input_box_box";
      typeIconClass = "picker_input__icon";
      themeClass =  "picker_input_box__text_dark";
      break;
  }

  if (selected) {
    typeMainClass += " picker_input_box_box_active";
  }
  
  return (
    <div className={typeMainClass} style={style} {...restProps} onClick={onClick}>
      {src && <Icon className={typeIconClass} src={srcLink} alt={text} />}

      {text && <p className={themeClass}>{text}</p>}
    </div>
  )});