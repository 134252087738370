import React, { useState, useContext } from 'react';
import HomePage from '../pages/HomePage';
import { useEffect } from 'react';
import { GlobalContext } from '../providers/GlobalContext';
import { getAllSubscriptions } from '../services/SubscriptionService';
import SubscriptionListPage from '../pages/SubscriptionListPage/SubscriptionListPage';
import BundleIcon from "../assets/icons/Bundle-Icon.svg";
import SubscriptionIcon from "../assets/icons/Subscription-Icon.svg";
import UseDimensions from '../hooks/UseDimensions';

function SubscriptionListScreen(props) {
  const {user, setUser} = useContext(GlobalContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [layout, setLayout] = useState(props.layout);
  const {height} = UseDimensions();

  const trainingsFiltered = subscriptions?.filter((subscription) => subscription.type == "ONE_TIME_PURCHASE" || subscription.type == "FREE").filter((subscription) => subscription.subType == "TRAINING") || [];
  const subscriptionFiltered = subscriptions?.filter((subscription) => subscription.type == "MONTHLY_SUBSCRIPTION") || [];
  const packagesFiltered = subscriptions?.filter((subscription) => subscription.type == "ONE_TIME_PURCHASE" || subscription.type == "FREE").filter((subscription) => subscription.subType == "PACKAGE")  || [];

  let trainingLayoutType = "UNDEFINED";
  let packageLayoutType = "UNDEFINED";
  let subscriptionLayoutType = "UNDEFINED";

  if (!user.username) {
    trainingLayoutType = "UNAUTHORIZED";
    packageLayoutType = "UNAUTHORIZED";
    subscriptionLayoutType = "UNAUTHORIZED";
  }

  if (trainingsFiltered?.length == 0 && user.username) {
    trainingLayoutType = "EMPTY";
  }

  if(trainingsFiltered?.length > 0) {
    trainingLayoutType = "LIST";
  }

  if (subscriptionFiltered?.length == 0 && user.username) {
    subscriptionLayoutType = "EMPTY";
  }

  if(subscriptionFiltered?.length > 0) {
    subscriptionLayoutType = "LIST";
  }

  if (packagesFiltered?.length == 0 && user.username) {
    packageLayoutType = "EMPTY";
  }

  if(packagesFiltered?.length > 0) {
    packageLayoutType = "LIST";
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  async function getSubscriptions() {
    const response = await getAllSubscriptions();
    setSubscriptions(response.body);

    console.debug("get subscriptions result: ", response);
  }

  function onSubscripitonClickHandler(id) {
    window.location.href = `/subscription/${id}`;
  }


  function onButtonClickHandler() {
    if(subscriptionLayoutType == "UNAUTHORIZED") {
      setIsLoginModalVisible(true);
      return;
    }

    if(subscriptionLayoutType == "EMPTY") {
      window.location.href = "/shop/trainer";
      return;
    }

    if(subscriptionLayoutType == "LIST") {
      window.location.href = "/shop/trainer";
      return;
    }

    if(subscriptionLayoutType == "UNDEFINED") {
      return;
    }
  }

  const values = [
    {title: "Balíčky", src: BundleIcon, selected: layout == "PACKAGE", onClick: () => setLayout("PACKAGE")},
    {title: "Tréninky", src: SubscriptionIcon, selected: layout == "TRAINING", onClick: () => setLayout("TRAINING")},
    {title: "Coaching", src: SubscriptionIcon, selected: layout == "SUBSCRIPTION", onClick: () => setLayout("SUBSCRIPTION")},
  ];


  return(
      <SubscriptionListPage
          height={height}
          layout={layout}
          trainings={trainingsFiltered}
          subscriptions={subscriptionFiltered}
          packages={packagesFiltered}
          isLoginModalVisible={isLoginModalVisible}
          setIsLoginModalVisible={setIsLoginModalVisible}
          onSubscripitonClick={onSubscripitonClickHandler}
          onButtonClick={onButtonClickHandler}
          trainingLayoutType={trainingLayoutType}
          packageLayoutType={packageLayoutType}
          subscriptionLayoutType={subscriptionLayoutType}
          headerValues={values}
      />
  );
}
export default SubscriptionListScreen;