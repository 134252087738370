import { formatPrice, breakAfterWord } from "../../utils/FormatUtils";
import Icon from "../Icon";
import RecomendedIcon from "../../assets/icons/Recomended-Icon.svg";
import PlusIcon from "../../assets/icons/Plus-Light-Icon.svg";
import "./ProductBox.css";


export default function ProductBox(props) {
  const {id, src, title, description, type, price, size, onClick, isRecomended, isFree, hidden, ...restProps} = props;
  
  const srcLink = src ? src : "";

  if(type == "EMPTY_SUBSCRIPTION" || type == "EMPTY_PACKAGE" || type == "EMPTY_TRAINING") {
    const types = {
      "EMPTY_SUBSCRIPTION": "coaching",
      "EMPTY_PACKAGE": "balíček",
      "EMPTY_TRAINING": "trénink"
    };

    return (
      <div className={size != "small" ? "product_box" : "small_product_box"} {...restProps} onClick={() => onClick(type)}>
        <Icon className="product_box__image" src={PlusIcon} alt={title} style={{ height: '24px', width: '24px', background: "transparent"}}/>
        <p className={"product_box_empty_text"}>Vytvořit nový {types[type]}</p>
      </div>
      );
  }

  return (
    <div className={size != "small" ? "product_box" : "small_product_box"} style={props.hidden ? {filter: "grayscale(100%)"}: {}} {...restProps} onClick={() => onClick(title, id)}>
      <Icon className="product_box__image" src={srcLink} alt={title} style={{objectFit: 'cover', height: '100%', width: '100%'}}/>
      <div className={size != "small" ? 'product_box__top_shadow' : 'small_product_box__top_shadow' }/>      

     
      <div className={size != "small" ? "product_box__box" : "small_product_box__box"}>
        <h5 className={size != "small" ? "product_box__box_title" : "small_product_box__box_title" }>{title}</h5>
        {size != "small" && <p className="product_box__box_description">{breakAfterWord(description, 75)}</p>}
      </div>

      <div className={size != "small" ? "product_box__price" : "small_product_box__price"}>
        {(type == "MONTHLY_SUBSCRIPTION" && size != "small") && <p className="product_box__price_text">Měsíčně</p >}
        {(type != "MONTHLY_SUBSCRIPTION" && size != "small") && <p className="product_box__price_text">Jednorázově</p >}
        <h5 className="product_box__price_bold">{formatPrice(price)}</h5>
      </div>

      <div className={size != "small" ? 'product_box__bottom_shadow' : 'small_product_box__bottom_shadow'}/>


      {(isRecomended && size != "small") && 
        <div className="product_box__recomended">
          <Icon className="product_box__recomended_icon" src={RecomendedIcon} alt="Recomended Icon"/>
            <p className="product_box__recomended_text">
              Doporučený
            </p> 
        </div>}

      {(isFree && size != "small") && 
        <div className="product_box__recomended">
          <Icon className="product_box__recomended_icon" src={RecomendedIcon} alt="Recomended Icon"/>
            <p className="product_box__recomended_text">
              Zdarma
            </p> 
      </div>}
    </div>
  )
}