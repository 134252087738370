import "./TrainerBox.css";
import EmailIcon from "../../assets/icons/Email-Icon.svg";
import PhoneIcon from "../../assets/icons/Phone-Icon.svg";
import Icon from "../Icon";
import { formatPhone, breakAfterWord } from "../../utils/FormatUtils";

export default function TrainerBox(props) {
  const {src, title, description, email, showEmailIcon = true, showPhoneIcon = true, phone, onClick, type, ...restProps} = props;
  
  const srcLink = src ? src : "";


  return (
    <div 
      className={type == 'light' ? "light_trainer_box" : "trainer_box"} 
      {...restProps} 
      onClick={() => onClick(title)}
    >
      {src && <Icon className={type == "light" ? "light_trainer_box__image" : "trainer_box__image"} src={srcLink} alt={title} />}
      <div className={type == 'light' ? 'light_trainer_box__left_shadow'  : 'trainer_box__left_shadow'}/>      

     
      <div className="trainer_box__box">
        <h3 className={type == "light" ? "light_trainer_box__box_title" : "trainer_box__box_title"}>{title}</h3>
        <p className={type == "light" ? "light_trainer_box__box_description" : "trainer_box__box_description"}>{breakAfterWord(description, 50)}</p>
      </div>

      <div className="trainer_box__contact_phone">
        {showPhoneIcon && <Icon className="trainer_box__contact_icon" src={PhoneIcon} alt="phone" />}
        <h5>{formatPhone(phone)}</h5 >
      </div>

      <div className="trainer_box__contact_email">
        {showEmailIcon && <Icon className="trainer_box__contact_icon" src={EmailIcon} alt="email" />}
        <h5>{email}</h5 >
      </div>

      <div className='trainer_box__bottom_shadow'/>
    </div>
  )
}