import React, { memo, useEffect, useMemo, useState } from 'react';
import IconBox from './IconBox';
import "./IconBoxGrid.css"

function IconBoxGrid(props) {
  const { values, type, theme, maxRows  } = props;
  const [maxItemsPerRow, setMaxItemsPerRow] = useState(0);

  useEffect(() => {
    function calculateMaxItems() {
      const container = document.querySelector(`.icon_box_grid_${type}`);
      if (container) {
        const containerWidth = container.offsetWidth;
        const boxWidth = 72 + 8; // width of each box

        // bug 340px ma byt 4 je 5
        setMaxItemsPerRow(Math.floor(containerWidth / (boxWidth - 8)));
      }
    }
    calculateMaxItems();
    window.addEventListener('resize', calculateMaxItems);
    return () => window.removeEventListener('resize', calculateMaxItems);
  }, [values]);

  let displayedRows = Math.ceil(values.length / maxItemsPerRow);
  displayedRows = Math.min(displayedRows, maxRows ? maxRows : displayedRows);

  console.log("displayedRows", displayedRows);
  console.log("maxItemsPerRow", maxItemsPerRow);

  const totalItems = displayedRows * maxItemsPerRow;
  const displayedValues = values.slice(0, totalItems);
  const placeholderCount = totalItems - displayedValues.length;

  return (
    <div className={`icon_box_grid icon_box_grid_${type}`}>
      {displayedValues.map((value, index) => (
        <div key={index} className="icon_box">
           <IconBox key={index} src={value.src} theme={theme} {...value} text={value.text} style={{ marginRight: "var(--dynamic-gap)" }}/>
        </div>
      ))}
      {Array.from({ length: placeholderCount }, (_, index) => (
        <div key={'placeholder-' + index} className="icon_box icon_box--placeholder"></div>
      ))}
    </div>
  );
}


export default React.memo(IconBoxGrid);