import "./ProductBoxGrid.css";
import { SOCIAL_ICONS } from "../../constants/Icons";
import ProductBox from "./ProductBox";

export default function ProductBoxGrid(props) {
  if(!props.values || props.values.length < 1) {
    return <></>;
  }

  return (
    <div className="product_box_grid">
      {props.values.map((value, index) => 
        <ProductBox size={"small"} key={index} onClick={props.onClick} type={props.type} {...value} />
      )}
    </div>
  )
   
  
}