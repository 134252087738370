import React from 'react';
import './HomePage.css';
import ButtonPrimary from '../components/Buttons/Primary/ButtonPrimary';
import { AnimatePresence, motion } from 'framer-motion';
import { Footer } from '../components/Navbar/Footer';

function HomePage({ pageHeight, pageIndex, pageRef, isLoaded, setIsLoaded, videos, onStartJourneyClick, onTrainingClick, onBundleClick, onSubscriptionClick, onTrainerClick, onIamTrainerClick, onHandleScroll, onHandleTouchMove  }) {
    return (
            <div className="home_page" style={{height: pageHeight}} ref={pageRef}>
                {videos.map((video, index) => (
                    <div key={index} className="home_page__container" style={{height: pageHeight}}>
                        { index == 0 ? 
                        <>
                            <div className="home_page__container_video_container"/>
                            <motion.div
                                style={{pointerEvents: "none"}}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: isLoaded ? 1 : 0 }}
                                transition={{ duration: 0.4, ease: "easeInOut" }}>
                                    <video 
                                    onCanPlay={() => setIsLoaded(true)}
                                    className="home_page__container_video"
                                    muted 
                                    autoPlay 
                                    loop 
                                    playsInline 
                                    controls={false}  
                        > 
                            <source src={video} type="video/mp4" />
                        </video>
                        </motion.div>
                        </>
                        : 
                        <motion.img
                            src={video}
                            // onLoad={() => setIsLoaded(true)}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isLoaded ? 1 : 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                            className="home_page__container_video"
                        />
                    }
                    </div>
                ))}

            <div className="home_page__container" style={{height: pageHeight / 2}}>
                <Footer show/>
            </div>

                {/* Home page */}
                <AnimatePresence>
                    {pageIndex === 0 && (
                        <motion.div
                        className="home_page__box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4, ease: "easeInOut" }}
                        >
                            <h1 className='home_page__box_h1'>Osobní trenéři online</h1>
                            <h5 className='home_page__box_h3'>Spojujeme certifikované trenéry a zájemce o fitness. Získej osobního online trenéra, který tě dovede k tvému cíli.</h5>
                            <div className='home_page__box_buttons'>
                                <ButtonPrimary 
                                title={"Chci online trenéra"} 
                                onClick={onStartJourneyClick} 
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                />
                                <ButtonPrimary 
                                title={"Jsem Trenér"} 
                                type={"outlined"} 
                                onClick={onIamTrainerClick}
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                    />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>


                {/* Training page */}
                <AnimatePresence>
                    {pageIndex === 1 && (
                        <motion.div 
                            className="home_page__box"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                        >
                            <h1 className='home_page__box_h1'>Osobní tréninky</h1>
                            <h5 className='home_page__box_h3'>Fyzické tréninky s certifikovaným trenérem, který ti pomůže dostat ze sebe maximum. Ať už chceš cvičit ve fitness centrech, doma či venku.</h5>
                            <div className='home_page__box_buttons'>
                                <ButtonPrimary 
                                title={"Zjistit více"} 
                                onClick={onTrainingClick}
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                    />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>


                {/* Online Coaching page */}
                <AnimatePresence>
                    {pageIndex === 2 && (
                        <motion.div 
                            className="home_page__box"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                        >
                            <h1 className='home_page__box_h1'>Online Coaching</h1>
                            <h5 className='home_page__box_h3'>Pravidelné vedení od osobního online trenéra, který tě dovede k tvému cíli. Vytvoří ti na míru šité plány a bude s tebou v kontaktu, ať jsi kdekoliv.</h5>
                            <div className='home_page__box_buttons'>
                                <ButtonPrimary 
                                title={"Zjistit více"} 
                                onClick={onSubscriptionClick}
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                    />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>


                {/* Package page */}
                <AnimatePresence>
                    {pageIndex === 3 && (
                        <motion.div 
                            className="home_page__box"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                        >
                            <h1 className='home_page__box_h1'>Balíčky a Služby</h1>
                            <h5 className='home_page__box_h3'>Jednorázové doplňkové služby od trenérů a skvělý způsob, jak získat tréninkový plán, jídelníček na míru nebo třeba zdravé recepty na jedno kliknutí.</h5>
                            <div className='home_page__box_buttons'>
                                <ButtonPrimary 
                                title={"Zjistit více"} 
                                onClick={onBundleClick} 
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                    {/* Complet page */}
                    <AnimatePresence>
                    {pageIndex === 4 && (
                        <motion.div 
                            className="home_page__box"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeInOut" }}
                        >
                            <h1 className='home_page__box_h1'>Balíčky zdarma</h1>
                            <h5 className='home_page__box_h3'>Zdarma dostupné užitečné materiály připravené certifikovanými trenéry. Užij si bezplatné návody ke cvičení bez skrytých poplatků či zadávání platebních údajů.</h5>
                            <div className='home_page__box_buttons'>
                                <ButtonPrimary 
                                title={"Zjistit více"} 
                                onClick={onBundleClick} 
                                onHandleScroll={onHandleScroll}
                                onHandleTouchMove={onHandleTouchMove}
                                />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
    );
}

export default HomePage;
