import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { GlobalContext } from "./providers/GlobalContext";
import Navbar from "./components/Navbar/Navbar";
import Home from "./screens/HomeScreen";
import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import TrainerBankAccountScreen from "./screens/TrainerBankAccountScreen";
import RegisterScreen from "./screens/RegisterScreen";
import RegisterTrainerScreen from "./screens/RegisterTrainerScreen";
import TrainerProfileScreen from "./screens/TrainerProfileScreen";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import CerUploadPage from "./pages/CerUploadPage/CerUploadPage";
import ArticleDesignScreen from "./screens/ArticleDetailScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import SubscriptionListScreen from "./screens/SubscriptionListScreen";
import SubscriptionDetailScreen from "./screens/SubscriptionDetailScreen";
import ClientProfileScreen from "./screens/ProfileEditInfoScreen";
import PackageListScreen from "./screens/PackageListScreen";
import TrainerWelcomeScreen from "./screens/TrainerWelcomeScreen";
import ShopPage from "./pages/ShopPage/ShopPage";
import ShopScreen from "./screens/ShopScreen";
import FilterScreen from "./screens/FilterScreen";
import FilterResultScreen from "./screens/FilterResultScreen";
import { Footer } from "./components/Navbar/Footer";
import CommonQuestionsScreen from "./screens/CommonQuestionsScreen";
import RegisterTrainerPage from "./pages/RegisterTrainerPage/RegisterTrainerPage";
import CreatePackageScreen from "./screens/CreatePackageScreen";
import TrainerInfoEditScreen from "./screens/TrainerInfoEditScreen";
import TrainerCertificateScreen from "./screens/TrainerCertificateScreen";
import DashboardScreen from "./screens/DashboardScreen";
import ProfileEditInfoScreen from "./screens/ProfileEditInfoScreen";
import ProfileScreen from "./screens/ProfileScreen";
import SubscriptionEditScreen from "./screens/SubscriptionEditScreen";
import BadRequestScreen from "./screens/BadRequestScreen";
import PaymentEditScreen from './screens/PaymentEditScreen';
import TrainerAffiliateScreen from './screens/TrainerAffiliateScreen';
import AnalyticsWrapper from './providers/GoogleAnalytics';
import AdminListScreen from './screens/AdminListScreen';
import DocumentScreen from './screens/DocumentScreen';
import LoadingScreen from "./screens/LoadingScreen";

function App() {
  const { user, setUser } = useContext(GlobalContext);

  return (
    <div className="App">
      <BrowserRouter>
      <AnalyticsWrapper>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/loading" element={<LoadingScreen />} />
          <Route path="/error/:code" element={<BadRequestScreen />} />

          <Route
            path="/shop/trainer"
            element={<ShopScreen layout={"TRAINER"} />}
          />
          <Route
            path="/shop/training"
            element={<ShopScreen layout={"TRAINING"} />}
          />     
          <Route
            path="/shop/package"
            element={<ShopScreen layout={"PACKAGE"} />}
          />
          <Route
            path="/shop/subscription"
            element={<ShopScreen layout={"SUBSCRIPTION"} />}
          />

          <Route path="/questions" element={<CommonQuestionsScreen />} />

          <Route path="/filter/:type" element={<FilterScreen />} />
          <Route path="/filter/:type/:ids" element={<FilterScreen />} />
          <Route
            path="/filter/:type/:ids/result"
            element={<FilterResultScreen />}
          />

          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route
            path="/register/:redirect/:emailParam"
            element={<RegisterScreen />}
          />

          <Route
            path="/register/:redirect/:emailParam/:affiliateId"
            element={<RegisterScreen />}
          />
          <Route path="/trainer/welcome" element={<TrainerWelcomeScreen />} />
          <Route path="/trainer/register" element={<RegisterTrainerScreen />} />
          <Route
            path="/trainer/edit/info"
            element={<TrainerInfoEditScreen />}
          />
          <Route
            path="/trainer/edit/bank"
            element={<TrainerBankAccountScreen />}
          />
          <Route
            path="/trainer/edit/certificate"
            element={<TrainerCertificateScreen />}
          />
          <Route path="/trainer/dashboard" element={<DashboardScreen />} />

          <Route
            path="/subscription/:id"
            element={<SubscriptionDetailScreen />}
          />

          <Route
            path="/subscription/edit/:id"
            element={<SubscriptionEditScreen />}
          />

          <Route
            path="/trainer/package/create"
            element={<CreatePackageScreen />}
          />
          <Route
            path="/trainer/package/update/:id"
            element={<CreatePackageScreen />}
          />

          <Route path="/trainer/affiliate/:username" element={<TrainerAffiliateScreen />} />

          <Route
            path="/training"
            element={<SubscriptionListScreen layout={"TRAINING"} />}
          />          
          <Route
            path="/package"
            element={<SubscriptionListScreen layout={"PACKAGE"} />}
          />
          <Route
            path="/subscription"
            element={<SubscriptionListScreen layout={"SUBSCRIPTION"} />}
          />

          <Route
            path="/subscription/:id"
            element={<SubscriptionDetailScreen />}
          />

          <Route path="/profile" element={<ProfileScreen />} />
          <Route
            path="/profile/edit/info"
            element={<ProfileEditInfoScreen />}
          />

          <Route
            path="/profile/edit/bank"
            element={<PaymentEditScreen />}
          />

          <Route
            path="/admin/list"
            element={<AdminListScreen />}
          />


          <Route path="/checkout/:type/:id" element={<CheckoutScreen />} />

          <Route path="/document/:path" element={<DocumentScreen />} />

          <Route path="/conditionsPayment" element={<Navigate to="/document/nextcoach.cz:cloud:public:conditions.pdf" />} />
          <Route path="/conditionsBusiness" element={<Navigate to="/document/nextcoach.cz:cloud:public:conditions.pdf" />} />
          <Route path="/conditions" element={<Navigate to="/document/nextcoach.cz:cloud:public:conditions.pdf" />} />
          <Route path="/privacy" element={<Navigate to="/document/nextcoach.cz:cloud:public:conditions.pdf" />} />

          <Route path="/:username/" element={<TrainerProfileScreen />} />
          <Route path="/:username/:article" element={<ArticleDesignScreen />} />

          <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
        <Footer />
        </AnalyticsWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
