import React from "react";
import './ShopPage.css';
import {DinamicSwitch, DinamicSwitchHeader, SwitchHeader} from "../../components/Navbar/SwitchHeader";
import ButtonPrimary from "../../components/Buttons/Primary/ButtonPrimary";
import Icon from "../../components/Icon";
import ProductBoxGrid from "../../components/Boxes/ProductBoxGrid";
import TrainerBox from "../../components/Boxes/TrainerBox";
import {Helmet} from "react-helmet";

function ShopPage(props) {
  const {height, headerValues, productsValues, layoutValues, layout, subLaylout, trainings, packages, subscriptions, trainers, onArticleClick, onTrainerClick} = props;


  const currentValue = layoutValues.find(value => value.type == layout || value.type == subLaylout) || {};

  return (
    <>
      <Helmet>
        <title>Nextcoach - Obchod</title>
        <meta name="description" content="Prodej balíčků, předplatného a služeb trenérů" />
      </Helmet>
      <SwitchHeader values={headerValues}/>
      <div className="shop_page">
        <div className='shop_page__head'>
                  <div className='shop_page__head_header'>
                      <Icon 
                        className='shop_page__head_header_image'
                        alt={`trainer welcome image`} 
                        src={currentValue.src}
                      />
                      <div className='shop_page__head_header_shadow_top'/>
                      <div className='shop_page__head_header_shadow'/>
                      <h3 className='shop_page__head_header_h2'>
                        {currentValue.title}
                      </h3>
                      <p className='shop_page__head_header_h4'>
                        {/* {currentValue.description} */}
                      </p>

                </div>

                <div className="shop_page__head_header_button">  
                        {currentValue.texts?.map((text, index) => 
                          <div key={index} className="shop_page__head_header_button_box">   
                            <h5>{text.title}</h5>
                            <p>{text.description}</p>
                          </div>
                        )}
                        {currentValue.buttonText && <ButtonPrimary title={currentValue.buttonText} onClick={currentValue.onButtonClick} /> } 
                      </div>
                      
              </div>
              <div className="shop_page__body">
                <div className="shop_page__body_box">
                      {layout == "PRODUCTS" && 
                        <div className="shop_page__body_box_header">
                          <DinamicSwitch values={productsValues}/>
                            {subLaylout == "TRAINING" && 
                              <ProductBoxGrid
                                values={trainings}
                                type={"PACKAGE"}
                                onClick={onArticleClick}
                              />
                            }
                            {subLaylout == "SUBSCRIPTION" &&
                              <ProductBoxGrid
                                values={subscriptions}
                                type={"MONTHLY_SUBSCRIPTION"}
                                onClick={onArticleClick}
                              />
                            }
                            {subLaylout == "PACKAGE" && 
                              <ProductBoxGrid
                                values={packages}
                                type={"PACKAGE"}
                                onClick={onArticleClick}
                              />
                            }
                        </div>
                      }
                      {layout == "TRAINER" &&
                        <div className="shop_page__body_box_trainer">
                          {trainers.map((trainer, index) => 
                            <TrainerBox  
                              key={index}
                              title={trainer?.name}
                              description={trainer?.description}
                              email={trainer?.email}
                              phone={trainer?.phoneNumber}
                              src={trainer?.imageUri?.length > 0 && trainer?.imageUri[0]}
                              onClick={onTrainerClick}
                          />)}
                        </div>
                      }
                </div>
              </div>
      </div>
    </>
  );

}
export default ShopPage;
